/**
 * @file faq.jsx
 * @description FAQ page
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React, { useEffect } from "react";
import Meta from "../components/Meta";
import Hero from "../components/hero";
import bulmaCollapsible from "@creativebulma/bulma-collapsible";
import { config } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

/**
 * @function FaqPage
 * @returns {JSX.Element} The FAQ page
 */

function FaqPage() {
  config.autoAddCss = false;

  useEffect(() => {
    bulmaCollapsible.attach();
  }, []);

  const questions = [
    {
      question: "How can I install “Smooth”?",
      answer:
        '(1) Download the style pack directly from our site.<br/>(2) Open the <abbr title="" data-tooltip="Administration Control Panel">ACP</abbr> and go to Configuration and click on the + next to Packages. On the new page, click Upload Package, select the package you just downloaded from your local computer and click Submit to upload the package and start the installation. <br/>See also: <a href="https://manual.woltlab.com/en/packages/#upload-packages">https://manual.woltlab.com/en/packages/#upload-packages</a> <br/><br/>(3) After the installation is complete, go to Customization → Styles → Styles, where you can make the style pack the default style for your forum. <br/>See also: <a href="https://manual.woltlab.com/en/styles/#manage-styles">https://manual.woltlab.com/en/styles/#manage-styles</a>',
    },
    {
      question: "Can I install “Smooth” on the WoltLab Cloud?",
      answer:
        'Yes, you can install “Smooth” on the WoltLab Cloud. <br/><br/>(1) Just open the <abbr title="" data-tooltip="Administration Control Panel">ACP</abbr> and go to Configuration and click on the + next to Packages. <br/>(2) On the new page, enter “Smooth” as a search term. You will then see the style pack in the search results. <br/>(3) Click on the + to install the package. <br/>See also: <a href="https://manual.woltlab.com/en/packages/#install-packages">https://manual.woltlab.com/en/packages/#install-packages</a><br/><br/>(4) After the installation is complete, go to Customization → Styles → Styles, where you can make the style pack the default style for your forum. <br/>See also: <a href="https://manual.woltlab.com/en/styles/#manage-styles">https://manual.woltlab.com/en/styles/#manage-styles</a>',
    },
    {
      question: "Can I customize “Smooth”?",
      answer:
        'Yes, you can customize “Smooth”. <br/><br/>You can customize the style pack in the <abbr title="" data-tooltip="Administration Control Panel">ACP</abbr> (Customization → Styles → Smooth). <br/>See also: <a href="https://manual.woltlab.com/en/styles/#manage-styles">https://manual.woltlab.com/en/styles/#manage-styles</a>',
    },
    {
      question: "Do I get updates?",
      answer:
        'Yes, you will get updates for the style pack. <br/><br/>You can download the lastest version of the style pack from our site or from the <abbr title="" data-tooltip="Administration Control Panel">ACP</abbr> (Configuration → Packages) or directly from our site (<a href="https://smooth.felix-d1strict.de">https://smooth.felix-d1strict.de/download</a>).',
    },
    {
      question: "When is the next version coming?",
      answer:
        "Smooth will continue to receive updates in the future. However, the duration of further development depends on various factors. (For example, number of wishes, scope of wishes, time in spare time, etc.).<br/><br/>This means: Even if a new version is already being developed, no exact date for the release can be given. In the past, I have too often given an approximate time, which I later significantly exceeded due to the factors mentioned above.",
    },
    {
      question: "How can I get support?",
      answer:
        'You can get support for the style pack in our ticket system. <br/><br/>Open a ticket in our ticket system (<a href="https://felix-d1strict.de/support">https://felix-d1strict.de/support</a>). Alternatively, you can also contact us via E-Mail (<a href="mailto:smooth+support@felix-d1strict.de">smooth+support@felix-d1strict.de</a>) or via our Discord server (<a href="https://discord.gg/h6YHJYb9jk">https://discord.gg/h6YHJYb9jk</a>).',
    },
    {
      question: "How can I report a bug?",
      answer:
        'You can report bugs in our ticket system. <br/><br/>Open a ticket in our ticket system (<a href="https://felix-d1strict.de/support">https://felix-d1strict.de/support</a>). Alternatively, you can also contact us via E-Mail you can also contact us via E-Mail (<a href="mailto:smooth+support@felix-d1strict.de">smooth+support@felix-d1strict.de</a>) or via our Discord server (<a href="https://discord.gg/h6YHJYb9jk">https://discord.gg/h6YHJYb9jk</a>).',
    },
    {
      question: "Am I allowed to remove the branding?",
      answer:
        'No, you are not allowed to remove the branding. <br/><br/>The branding is a small link to our site. This is a small thank you for the free style pack. If you want to remove the branding, you can also buy an "Unbranded" license. <br/><br/>You can find more information about the "Unbranded" license on our site (<a href="https://felix-d1strict.de">https://felix-d1strict.de</a>).',
    },
  ];

  return (
    <div data-allow-multiple="true">
      <Meta
        title="Frequently Asked Questions"
        description="Here you can find answers to the most frequently asked questions."
      />
      <Hero
        title="Frequently Asked Questions"
        subtitle="Here you can find answers to the most frequently asked questions."
      />
      <div className="section">
        <div className="container">
          <div className="accordion_first">
            {questions.map((question, index) => (
              <article className="message is-primary" key={index}>
                <a
                  href={"#collapsible-message-accordion-" + index}
                  className="has-text-white"
                  data-action="collapse"
                  id={"collapsible-message-accordion-trigger-" + index}
                >
                  <div className="message-header">
                    <p>{question.question}</p>
                    <span className="icon is-small">
                      <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                  </div>
                </a>
                <div
                  id={"collapsible-message-accordion-" + index}
                  className="message-body is-collapsible"
                  data-parent="accordion_first"
                >
                  <div className="message-body-content">
                    <p dangerouslySetInnerHTML={{ __html: question.answer }} />
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaqPage;
